import store from "@/store"
let igedit = {};
let vm;
let listMethods = {
};
let nameComputed = {

};
export default {
  data() {
   return ;
  },
  methods: listMethods,
  computed: nameComputed,
  beforeRouteEnter(to, from, next) {
    next();
  }
};