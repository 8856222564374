<template>
  <div>
    <div class="row">
      <div class="col-2">
        <font-awesome-icon :icon="['fas', 'spinner']" :mask="['fas', 'circle']" />
      </div>
      <div class="col-2">
        <div class="text-center my-3">
          <b-btn v-b-popover.click.top="'I am popover content!'" variant="outline-success" title="Popover Title">Hover
            Me</b-btn>
        </div>
      </div>
      <div class="col-2 hello">3</div>
    </div>
  </div>
</template>

<script>
import control from './Login.js'
export default control;
</script>